<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store"
var state = store;
export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        PageHeader,

    },
    data() {
        return {

            SubscriptionFeatures: [],
            subscriptionPackages: [],
            page: 1,
            limit: 20,
            tot_pages: 0,
            searchHrLeaveTypes: null,
            SubscriptionFeaturesSearchMode: false,
            searchModel: "",
            state: state,

        };
    },
    methods: {


        addSubscriptionFeatures() {
            var data = {
                title: "popups.add",
                inputs: [
                    { model: "code", type: 'text', label: "subscription_features.code" },
                    { model: "name", type: "text", label: "subscription_features.name" },
                    { model: "name_en", type: "text", label: "subscription_features.name_en" },
                ],
                buttons: [
                    {
                        text: "popups.add",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.post("subscription-features", obj, null, true).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);
        },
        editSubscriptionFeatures(app) {
            var data = {
                title: "popups.edit",
                inputs: [
                
                    // { model: "code", value: app.code, type: "text", label: "subscription_features.code" },
                    { model: "name", value: app.name, type: "text", label: "subscription_features.name" },
                    { model: "name_en", value: app.name_en, type: "text", label: "subscription_features.name_en" }
                ],
                buttons: [
                    {
                        text: "popups.edit",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.put("subscription-features", app.id, obj).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);
        },
        search() {
            this.SubscriptionFeaturesSearchMode = true;
            this.tot_pages = 0;
            this.http.post("subscription-features/search", {
                search: this.searchModel,
                limit: this.limit,
                page: this.page,
                col: "code",
            }, null, true)
                .then((res) => {
                    this.SubscriptionFeatures = res.data;
                });
        },
        cancelappsearchMode() {
            this.searchModel = "";
            this.SubscriptionFeaturesSearchMode = false;
            this.get(this.page);
        },


        deleteSubscriptionFeatures(app) {
            var data = {
                title: "popups.delete",
                msg: "popups.delmessage",
            };
            this.popup.confirm(data).then((resp) => {
                if (resp) {
                    this.http.delete("subscription-features", app.id).then(() => {
                        this.get(this.page);
                    });
                }
            });
        },

        get(page) {
            console.log(page);
            this.http.post("subscription-features/paginate", {
                limit: this.limit,
                page: page,
            }, null, true)
                .then((res) => {
                    this.page = page;
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.SubscriptionFeatures = res.data;
                });
        },
    },
    created() {
        this.get(1);
    },
    mounted() {
    },
};
</script>

<template>
        <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :title="$t('menu.menuitems.subscription_features.subItems.subscription_features')" />
        <div class="row">
            <div class="col-6 mb-3">
                <div class="search-box chat-search-box w-50">
                    <div class="position-relative">
                        <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')"
                            v-model="searchModel" style="background-color: #2a3042 !important" />
                        <i class="bx bx-search-alt search-icon text-light"></i>
                    </div>
                </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
                <button class="btn-close text-light" v-if="SubscriptionFeaturesSearchMode"
                    @click="cancelappsearchMode()"></button>
                
            </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                    <th scope="col">{{ $t("subscription_features.id") }}</th>

                    <th scope="col">{{ $t("subscription_features.name") }}</th>

                    <th scope="col">{{ $t("subscription_features.name_en") }}</th>

                    <th scope="col">{{ $t("subscription_features.created") }}</th>
                    <th scope="col">{{ $t("subscription_features.operations") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="app in SubscriptionFeatures" :key="app" class="text-center">
                    <td>{{ app?.id }}</td>
                    <td>{{ app?.name }}</td>
                    <td>{{ app?.name_en }}</td>
                    <td>{{ new Date(app?.created).toLocaleString('en-GB') }}</td>

                    <td class="d-flex">
                        <a class="btn btn-primary mx-1" @click="editSubscriptionFeatures(app)" href="javascript: void(0);"
                            role="button">{{ $t("popups.edit") }}</a>
                       <!-- <a class="btn btn-danger" href="javascript: void(0);" role="button"
                            @click="deleteSubscriptionFeatures(app)">{{ $t("popups.delete") }}</a> -->
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages == 20">
            <!--   Apps  Pagination     -->
            <ul class="pagination pagination-rounded justify-content-center mb-2">
                <li class="page-item" :class="{ disabled: page == 1 }">
                    <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
                        <i class="mdi mdi-chevron-left"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                    <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
                        p
                    }}</a>
                </li>

                <li class="page-item" :class="{ disabled: page == total_pages }">
                    <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
                        <i class="mdi mdi-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>


        <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
